import PropTypes from 'prop-types'
import {
  Typography, Card, CardContent,
  Box,
} from '@material-ui/core'
import Image from '@components/atoms/image'
import ButtonCta from '@components/atoms/button-cta'
import SrOnly from '@components/atoms/sr-only'

import useStyles from './involvement.styles'

const DesktopInvolvementItem = (props) => {
  const {
    image,
    contentfulMetadata,
    headline,
    reference,
    externalLink,
    ctaText,
    nextSteps,
    step,
  } = props
  const styles = useStyles({ nextSteps })
  const bg = image.url

  const hasTag = contentfulMetadata.tags && !!contentfulMetadata.tags.length

  return (
    <Card className={styles.slide} alt={headline}>
      <Box className={styles.cardImgDesktop}>
        <Image src={bg} alt="" />
      </Box>
      <CardContent className={styles.cardContent}>
        <div className={styles.topCardText}>
          {hasTag && (
            <Typography variant="overline" className={styles.tag}>
              {contentfulMetadata.tags[0].name.toUpperCase()}
            </Typography>
          )}
          {step && (
            <Typography variant="overline" className={styles.tag}>
              {`STEP 0${step}`}
            </Typography>
          )}
          <Typography variant="h5" className={styles.cardHeadline}>
            {headline}
          </Typography>

        </div>
      </CardContent>
      {(!nextSteps || ctaText) && (
        <ButtonCta
          className={styles.slideCta}
          variant="text"
          arrow
          reference={reference}
          href={externalLink}
        >
          {ctaText}
          <SrOnly>{`about '${headline}`}</SrOnly>
        </ButtonCta>
      )}
    </Card>
  )
}

DesktopInvolvementItem.propTypes = {
  headline: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  ctaText: PropTypes.string,
  contentfulMetadata: PropTypes.object,
  externalLink: PropTypes.string,
  reference: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
  }),
  nextSteps: PropTypes.bool,
  step: PropTypes.number,
}

export default DesktopInvolvementItem
