import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: ({ nextSteps }) => ({
    background: nextSteps ? '#FFFFFF' : theme.palette.background.default,
    paddingTop: nextSteps && theme.spacing(4),
    paddingBottom: nextSteps && theme.spacing(8),
  }),
  headlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headlineLeft: {
    width: '55%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  headline: {
    color: theme.palette.secondary.light,
  },
  subtitle: ({ nextSteps }) => ({
    marginTop: theme.spacing(2),
    marginBottom: nextSteps ? theme.spacing(2) : theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  }),
  asterisk: {
    paddingTop: theme.spacing(3),
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
      paddingBottom: theme.spacing(2),
    },
  },
  cta: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardGrid: ({ nextSteps }) => ({
    justifyContent: nextSteps ? 'space-between' : 'flex-start',
    display: nextSteps && 'grid',
    gridTemplateColumns: nextSteps && 'repeat(auto-fit, minmax(300px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    '&>div': {
      display: nextSteps && 'flex',
      justifyContent: nextSteps && 'center',
      flexGrow: nextSteps && 1,
    },
  }),
  slide: ({ nextSteps }) => ({
    position: 'relative',
    overflow: 'hidden',
    height: nextSteps ? '375px' : '408px',
    minWidth: '350px',
    maxWidth: '408px',
    borderRadius: '8px',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    flexGrow: nextSteps && 1,
  }),
  cardImgDesktop: ({ nextSteps }) => ({
    height: !nextSteps && '230px',
    width: !nextSteps && '408px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
  cardImgMobile: {
    height: '350px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  cardContent: ({ nextSteps }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'absolute',
    top: nextSteps ? '210px' : '229px',
    height: '179px',
    width: '100%',
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    paddingRight: nextSteps && theme.spacing(3),
  }),
  topCardText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    gap: '2px',
  },
  cardHeadline: {
    color: theme.palette.secondary.light,
  },
  tag: {
    color: theme.palette.primary.dark,
    height: '30px',
  },
  hide: {
    display: 'none',
  },
  involvementCta: {
    padding: 0,
    height: '35px',
  },
  slideCta: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '&>span': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      height: '92%',
      [theme.breakpoints.down('md')]: {
        height: '94%',
      },
      '&>svg': {
        marginBottom: '8px',
        [theme.breakpoints.down('md')]: {
          marginBottom: '6px',
        },
      },
    },
  },

  // MOBILE STYLES //
  mobileRoot: {
    overflow: 'hidden',
    paddingBottom: theme.spacing(8),
  },
  mobileHeadlineContainer: {
    width: '100%',
    textAlign: 'center',
  },
  mobileHeadline: {
    color: theme.palette.secondary.light,
    margin: '50px auto 20px',
  },
  mobileSlide: {
    height: '517px !important',
    width: '100%',
    borderRadius: '8px',
    flexDirection: 'column',
    overflow: 'hidden',
    boxSizing: 'border-box',
    boxShadow: theme.palette.shadow.cardBoxShadow,
  },
  imageContainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
  },
  mobileCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'absolute',
    bottom: 0,
    height: '170px',
    width: '100%',
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    borderRadius: '0 0 8px 8px',
  },
  mobileTopCardText: {
    border: '1px solid black',
  },
  ctaBottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.75rem',
    marginBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export default useStyles
