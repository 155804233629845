import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fancyLink: {
    textDecoration: 'none',
    boxShadow: `0 2px 0 ${theme.palette.primary.main}`,
    color: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: 'rgba(171,	145,	87, 0.25)',
      boxShadow: 'none',
      color: theme.palette.primary.dark,
    },
  },
  darkenOverlay: {
    position: 'relative',
    '&::after': {
      content: "' '",
      position: 'absolute',
      display: 'block',
      zIndex: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.3)',
    },
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
  },
  button: {
    lineHeight: '1rem',
  },
}))

export default useStyles
