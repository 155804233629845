import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { RenderLinkProps } from '@components/renderers'
import Button from '@material-ui/core/Button'
import useHover from '@hooks/useHover'
import CtaArrow from './cta-arrow'
import useStyles from './styles'

// eslint-disable-next-line object-curly-newline
const ButtonCta = React.forwardRef(({
  className,
  href,
  children,
  prefetch,
  dataAttrs,
  reference,
  externalLink,
  color,
  size,
  variant,
  arrow,
  startIcon,
  onClick,
}, ref) => {
  let linkProps = {}
  const [hoverRef, isHovered] = useHover()

  const styles = useStyles()

  if (!href) {
    const selfHref = reference
      && RenderLinkProps({ slug: reference.slug, type: reference.__typename })
    const external = externalLink && { href: externalLink }
    linkProps = external || selfHref
  }

  return (
    externalLink
      ? (
        <Button
          color={color}
          size={size}
          variant={variant}
          {...linkProps}
          {...dataAttrs}
          className={`${styles.button} ${className}`}
          target="_blank"
          rel="noopener noreferrer"
          ref={hoverRef}
          startIcon={startIcon || null}
          onClick={onClick}
        >
          {!!arrow && (
            <>
              {children}
              <CtaArrow hovered={isHovered} />
            </>
          )}
          {!arrow && (
            <>
              {children}
            </>
          )}
        </Button>
      )
      : (
        <Link
          href={href || linkProps?.href || ''}
          prefetch={prefetch}
          ref={ref}
          passHref
        >
          <Button
            color={color}
            size={size}
            variant={variant}
            {...dataAttrs}
            className={`${styles.button} ${className}`}
            ref={hoverRef}
            startIcon={startIcon || null}
            onClick={onClick}
          >
            {!!arrow && (
              <>
                {children}
                <CtaArrow hovered={isHovered} />
              </>
            )}
            {!arrow && (
              <>
                {children}
              </>
            )}
          </Button>
        </Link>
      )
  )
})

ButtonCta.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  prefetch: PropTypes.bool,
  dataAttrs: PropTypes.object,
  externalLink: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['default', 'small']),
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  arrow: PropTypes.bool,
  reference: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
  }),
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
}

ButtonCta.defaultProps = {
  arrow: false,
}

export default ButtonCta
