/* eslint-disable react/destructuring-assignment,no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
    if (window.rg4js != null) window.rg4js('send', { error })
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorDisplay || <div style={{ display: 'none' }}>There was an error</div>
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  errorDisplay: PropTypes.node,
}

export default ErrorBoundary
