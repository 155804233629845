export const getClosestAlgoliaItemsByIp = async (
  index,
  coachesToShow,
  searchRadius = 100 * 1609,
  filters = '',
  attributesToRetrieve = [],
) => {
  const { hits, aroundLatLng } = await index.search('', {
    aroundLatLngViaIP: true,
    aroundRadius: searchRadius,
    filters,
    attributesToRetrieve,
    hitsPerPage: coachesToShow,
  })
  if (!hits || hits.length === 0) return { hits: [], aroundLatLng }
  return { hits, aroundLatLng }
}
