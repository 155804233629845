import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import ButtonCta from '@components/atoms/button-cta'
import SrOnly from '@components/atoms/sr-only'
import Image from '@components/atoms/image'
import useStyles from './involvement.styles'


const MobileInvolvementItem = (props) => {
  const {
    slide,
    className,
    nextSteps,
    step,
  } = props

  const {
    imageMobile,
    image,
    headline,
    contentfulMetadata,
    ctaText,
    externalLink,
    reference,
  } = slide

  const styles = useStyles({ nextSteps })

  let bg = ''
  if (imageMobile) {
    bg = `${imageMobile.url}?h=350&fl=progressive&q=80`
  } else {
    bg = `${image.url}?h=350&fl=progressive&q=80`
  }

  const hasTag = contentfulMetadata.tags && !!contentfulMetadata.tags.length

  return (
    <Card className={`${styles.mobileSlide} ${className}`}>
      <Box className={styles.cardImgMobile}>
        <Image src={bg} alt={headline} />
      </Box>
      <CardContent className={styles.mobileCardContent}>
        <div className={styles.topCardText}>
          {hasTag && (
            <Typography variant="overline" className={styles.tag}>
              {contentfulMetadata.tags[0].name.toUpperCase()}
            </Typography>
          )}
          {step && (
            <Typography variant="overline" className={styles.tag}>
              {`STEP 0${step}`}
            </Typography>
          )}
          <Typography variant="h5" className={styles.cardHeadline}>
            {headline}
          </Typography>
        </div>
      </CardContent>
      {(!nextSteps || ctaText) && (
        <ButtonCta
          className={styles.slideCta}
          variant="text"
          arrow
          href={externalLink}
          reference={reference}
        >
          {ctaText}
          <SrOnly>{`about '${headline}`}</SrOnly>
        </ButtonCta>
      )}
    </Card>
  )
}

MobileInvolvementItem.propTypes = {
  slide: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    imageMobile: PropTypes.shape({
      url: PropTypes.string,
    }),
    ctaText: PropTypes.string,
    externalLink: PropTypes.string,
    contentfulMetadata: PropTypes.object,
    reference: PropTypes.shape({
      __typename: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
  nextSteps: PropTypes.bool,
  step: PropTypes.number,
}

MobileInvolvementItem.defaultProps = {
}

export default MobileInvolvementItem
