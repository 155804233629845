import VisibilitySensor from 'react-visibility-sensor'
import { useState } from 'react'

const HocLazyLoad = BaseComponent => (props) => {
  const [showImages, setShowImages] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const onChange = (isVisible) => {
    if (!loaded && isVisible) {
      setShowImages(isVisible)
      setLoaded(true)
    }
  }

  return (
    <VisibilitySensor onChange={onChange} partialVisibility active={!loaded} offset={{ top: 100 }}>
      <BaseComponent {...props} showImages={showImages} />
    </VisibilitySensor>
  )
}

export default HocLazyLoad
