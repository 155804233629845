import { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch'
import { getClosestAlgoliaItemsByIp } from '@lib/algolia'

const client = algoliasearch(
  process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY,
)
const algoliaIndex = client.initIndex(process.env.ALGOLIA_COACH_INDEX_NAME)

const useNearestCoaches = (coachesToShow, searchRadius = 100 * 1609, filters = '') => {
  const [nearestCoaches, setNearestCoaches] = useState([])
  const [location, setLocation] = useState('')
  const attributesToRetrieve = [
    'city',
    'state',
    'customSlug',
    'facilities',
    'profilePhoto',
    'firstName',
    'lastName',
    'displayName',
    'bookable',
    'bookingURL',
    'hasIntroVideo',
    'universalId',
  ]

  useEffect(() => {
    const getNearestCoaches = async () => {
      const { hits: coaches, aroundLatLng } = await getClosestAlgoliaItemsByIp(
        algoliaIndex,
        coachesToShow,
        searchRadius,
        filters,
        attributesToRetrieve,
      )
      if (coaches) {
        setNearestCoaches(coaches)
      }
      setLocation(aroundLatLng)
    }
    getNearestCoaches()
  }, [coachesToShow])

  return { nearestCoaches, location }
}

export default useNearestCoaches
